@font-face {
	font-family: "MaisonNeue-Book";
	src: url("MaisonNeue-Book.ttf") format("truetype");
}
@font-face {
	font-family: "RecifeDisplay-Bold";
	src: url("RecifeDisplay-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "MaisonNeue-Light";
	src: url("MaisonNeue-Light.ttf") format("truetype");
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-size: 62.5%;
}

html,
body {
	padding: 0;
	margin: 0rem;
	overflow-x: hidden;
}

html {
	height: 100%;
}

body {
	height: 100vh;
}

img {
	max-width: 100%;
	height: auto;
	height: auto;
	object-fit: cover;
}

:root {
	--dominant-color: #717d6c;
	--hover-color: #717d6c8e;
	--secondary-color: #e0d8cf;
	--background-color: white;
	--accent-color-1: #000000;
	--accent-color-2: #a5a5a5;
}

li {
	list-style: none;
}

a,
p,
span {
	text-decoration: none;
	color: var(--accent-color-1);
	font-size: 1.9rem;
	font-family: "MaisonNeue-Light";
}
h1 {
	font-family: "RecifeDisplay-Bold";
	font-size: 4rem;
}
h2 {
	font-size: 3.5rem;
	font-family: "RecifeDisplay-Bold";
}

.header-container {
	position: sticky;
	display: flex;
	flex-wrap: wrap;
	background-color: hsla(0, 0%, 100%, 0.9);
	z-index: 4;
	top: 0px;
	bottom: auto;
	max-height: 100px;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	-webkit-box-shadow: 1px 2px 8px -2px var(--accent-color-2);
	box-shadow: 1px 2px 8px -2px var(--accent-color-2);
}

.routing-container {
	width: 100%;
	height: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

.mobile-menu-container {
	display: none;
}

.logo {
	width: 10rem;
}

.logo-container {
	flex: 2;
	margin: 2rem 0rem 2rem 2rem;
}

.navigation-container {
	position: relative;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex: 1 1 200px;
	flex-wrap: wrap;
	margin: 2rem 2rem 2rem 0rem;
}

.unlisted-nav {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.nav {
	text-transform: uppercase;
	letter-spacing: 0.2rem;
	text-align: center;
	padding: 1rem 2rem;
	margin-left: 1rem;
	border: 1px solid var(--accent-color-1);
	border-radius: 10px;
	transition: ease-in 150ms background-color;
}

.nav:hover {
	border: 1px solid var(--accent-color-1);
	background-color: var(--hover-color);
	transition: ease-in 150ms background-color;
	font-weight: 900;
}

a.nav:target {
	border: 1px solid var(--dominant-color);
	background-color: var(--hover-color);
}

/* homepage */

.home-container,
.about-container,
.p-container,
.contact-details-container,
.contact-container,
.contact-right,
.contact-left {
	position: relative;
	display: flex;
	justify-content: center;
}

.home-container {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 650px;
}

.image-container {
	margin-bottom: 2rem;
	max-width: 300px;
}

.shandennruss {
	border: 1px solid black;
	border-radius: 10px;
	box-shadow: 7px 6px 0px 0px #000000;
	object-fit: cover;
}

/* about */

.about-container {
	max-width: 100%;
	margin-top: 2rem;
}
.about-para {
	max-width: 80%;
	line-height: 3rem;
	-webkit-font-smoothing: antialiased;
}

.about-para-blog {
	color: var(--dominant-color);
}

.about-para-blog:hover {
	font-weight: 700;
	text-decoration: underline;
}

.dev {
	border-right: 2px solid rgba(0, 0, 0, 0.75);
	white-space: nowrap;
	overflow: hidden;
	width: 12%;
	animation: typewriter 2s steps(15) 1 normal both,
		blinkTextCursor 500ms steps(15) infinite normal;
	display: inline-flex;
	margin: 0 auto;
}

.des {
	font-style: italic;
}

@keyframes typewriter {
	0% {
		width: 0%;
	}
	100% {
		width: 100;
	}
}

@keyframes blinkTextCursor {
	from {
		border-right-color: 2px solid rgba(0, 0, 0, 0.75);
	}
	to {
		border-right-color: transparent;
	}
}

/* projects */

.banner-container {
	display: flex;
	min-height: 650px;
	flex-direction: column;
	justify-content: space-around;
	margin: 2rem;
	background: rgb(224, 216, 207);
	background: linear-gradient(
		68deg,
		rgba(224, 216, 207, 0.7763480392156863) 0%,
		rgba(240, 236, 236, 0.6138830532212884) 100%
	);
	padding: 2rem;
}

.project-banner {
	font-size: 15rem;
	font-family: "RecifeDisplay-Bold";
}

.project-names-container {
	display: flex;
}
.project-names-container {
	margin-top: 1rem;
	border-bottom: 1px solid black;
	padding-bottom: 3rem;
}
.p-names {
	margin-left: 3rem;
	cursor: pointer;
}

.p-names:hover {
	font-weight: 900;
}

.p-names:nth-child(1) {
	margin-left: 0;
}

.p-image-container {
	justify-content: center;
	max-width: 100%;
}

.project-img {
	height: auto;
	border: 1px solid black;
	border-radius: 10px;
	-webkit-box-shadow: 5px 2px 8px -2px var(--accent-color-2);
	box-shadow: 5px 2px 8px -2px var(--accent-color-2);
}

.p-container {
	min-height: 569px;
	margin: 2rem;
	border-bottom: 1px solid var(--accent-color-2);
	align-items: center;
}

.p-details-container,
.single-project-container {
	margin: 0rem 2rem 0 4rem;
}

.p-details-container {
	height: 330px;
	min-width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.p-service-container {
	margin-top: 0.5rem;
}

.p-service {
	font-size: 1.5rem;
	font-weight: 600;
}

.p-summary-container {
	margin-top: 2rem;
}

.p-summary {
	line-height: 3rem;
}

.p-button-container,
.contact-button-container {
	margin-top: 3rem;
}

.actions-container {
	display: flex;
	justify-content: space-between;
	flex-basis: 20%;
	margin-top: 2rem;
	align-items: baseline;
}

.p-button,
.contact-button,
.visit-container {
	padding: 1.5rem 4rem;
	position: relative;
	background-color: var(--hover-color);
	font-size: 1.5rem;
	-webkit-box-shadow: 2px 0px 8px var(--accent-color-2);
	box-shadow: 2px 0px 8px var(--accent-color-2);
	border: 1px solid black;
	border-radius: 10px;
	transition: ease-in 200ms box-shadow;
}

.visit-container {
	-webkit-box-shadow: unset;
	box-shadow: unset;
}

.p-button:hover,
.visit-container:hover,
.contact-button:hover {
	background-color: #788373b9;
	font-weight: 900;
	transition: ease-in 200ms background-color;
}

.p-container:nth-child(5) {
	border-bottom: none;
}

/* Project Details */
.top-image-container {
	min-height: 720px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 2rem;
}

.individual-img-container {
	max-width: 720px;
	margin-top: 4rem;
}

.individual-img {
	border: 1px solid black;
	border-radius: 10px;
	box-shadow: 7px 6px 0px 0px #000000;
}
.half-container {
	max-width: 720px;
}

.main-service-container {
	margin-top: 1rem;
}

.project-img {
	max-width: 100%;
}

.p-description-container {
	min-height: 620px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0rem 2rem;
	background-color: black;
}

.single-detail-container {
	max-width: 50%;
	width: 100%;
	min-height: 520px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0rem auto;
}

.subtitle {
	line-height: 1.4;
}

.more-info-container {
	margin-top: 3rem;
	line-height: 3rem;
}

.more-info,
.more-info-2,
.subtitle {
	color: white;
}

.more-info.two {
	margin-top: 2rem;
}

.visit-web {
	cursor: pointer;
	color: white;
}

.fa.fa-angle-double-right {
	font-size: 3.5rem;
	margin-bottom: 1rem;
}

.arrow {
	display: flex;
	align-items: flex-end;
	margin-right: 2rem;
}

.arrow.container .fa.fa-angle-double-right:hover {
	color: var(--dominant-color);
	transition: all 200ms ease-in;
	transform: translateX(2px);
}

.arrow.container {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: end;
	margin: 0rem 2rem;
}

.arrow-link {
	margin-left: 1rem;
}

.next {
	font-weight: 700;
	cursor: pointer;
}

.bottom-image-container {
	min-height: 700px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 0 2rem;
}

.mobile-image {
	max-width: 240px;
}

.m-image {
	border: 1px solid black;
	border-radius: 10px;
	box-shadow: 7px 6px 0px 0px #000000;
}

/* Contact */

.contact-details-container {
	height: 630px;
	align-items: center;
	flex-direction: column;
}

.w-t-container {
	display: flex;
	align-items: flex-end;
}

.contact-summary-container {
	margin-top: 2rem;
}

.definition {
	margin-top: 1rem;
	font-size: 1.3rem;
}

/* footer */

.footer-container {
	display: flex;
	border-top: 1px solid grey;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	max-width: 1440px;
	width: 97%;
	margin: 0 auto;
	padding: 1rem 0rem;
}
.copy-right {
	font-weight: 900;
	display: flex;
}

.my-github {
	margin-right: 1rem;
}

.email-me,
.my-github {
	letter-spacing: 0.2rem;
	text-transform: uppercase;
	font-weight: 900;
	cursor: pointer;
}

.my-github:hover,
.email-me:hover {
	color: var(--dominant-color);
	font-weight: 900;
	letter-spacing: 0.2rem;
}

.fa.fa-copyright {
	font-size: 1.5rem;
}

/* Contact Details */

.contact-container {
	height: 630px;
	animation: transitionIn ease-in 800ms;
	margin: 0 2rem;
	overflow: hidden;
}

.contact-left {
	flex: 1;
	align-items: center;
}

.contact-header {
	font-size: 10rem;
	width: 90%;
	text-align: center;
	color: var(--accent-color-1);
	font-weight: 500;
}

.blob {
	position: absolute;
	top: 0;
	left: 0;
	fill: var(--dominant-color);
	width: 50vmax;
	z-index: -1;
	animation: move 10s ease-in-out infinite;
	transform-origin: 50% 50%;
}

@keyframes move {
	0% {
		transform: scale(1) translate(10px, 30px);
	}
	38% {
		transform: scale(0.8, 1) translate(0vw, 30vh) rotate(160deg);
	}
	40% {
		transform: scale(0.8, 1) translate(0vw, 30vh) rotate(160deg);
	}
	78% {
		transform: scale(1.3) translate(0vw, 30vh) rotate(-20deg);
	}
	80% {
		transform: scale(1.3) translate(0vw, 30vh) rotate(-20deg);
	}
	100% {
		transform: scale(1) translate(10px, 30px);
	}
}

.contact-right {
	flex: 1;
	flex-direction: column;
}

.contact-paragraph {
	line-height: 2.25rem;
	position: relative;
	display: flex;
	padding-bottom: 2rem;
	flex-direction: column;
}

@media (max-width: 900px) {
	h2 {
		font-size: 3rem;
	}
	.logo {
		width: 8rem;
	}

	img {
		height: auto;
	}

	.header-container {
		margin-bottom: 1rem;
		position: relative;
	}

	.mobile-menu-container {
		display: flex;
		align-items: center;
		z-index: 2;
		margin: 2rem 2rem 2rem 0rem;
	}

	.navigation-container {
		display: none;
	}

	.mobile-navigation-container {
		display: flex;
		position: fixed;
		z-index: 1;
		height: 100vh;
		width: 100%;
		background-color: var(--secondary-color);
	}
	.mobile-unlisted-nav {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.mobile-nav {
		font-size: 3rem;
	}

	.mobile-nav:hover {
		color: white;
	}

	.home-container {
		margin-top: 3rem;
		height: unset;
	}

	.shandennruss {
		width: 25rem;
	}

	.about-para {
		max-width: 100%;
		margin: 0rem 2rem 2rem 2rem;
	}

	.dev {
		overflow: hidden;
		border-right: 2px solid rgba(0, 0, 0, 0.75);
		white-space: nowrap;
		width: 40%;
		animation: typewriter 3s steps(14) 1 normal both,
			blinkTextCursor 500ms steps(14) infinite normal;
		display: inline-flex;
	}

	@keyframes typewriter {
		10% {
			width: 0ch;
		}

		0% {
			width: 15ch;
		}
	}

	.p-container {
		margin: 5rem 2rem;
		display: flex;
		flex-direction: column;
	}
	.p-image-container {
		margin-left: unset;
		min-height: 200px;
	}

	.p-details-container {
		margin: unset;
		margin-top: 3rem;
		margin-bottom: 2rem;
		min-width: 100%;
	}

	.p-summary-container {
		margin: 2rem 0rem;
	}

	.contact-details-container {
		height: 500px;
		margin: 0rem 2rem;
	}

	.p-description-container {
		flex-direction: column;
		flex-wrap: unset;
		height: unset;
		margin-top: 3rem;
	}

	.single-detail-container {
		margin: 2rem;
		max-width: 70%;
	}

	.contact-container {
		display: flex;
		flex-direction: column;
	}

	.contact-header {
		font-size: 5rem;
	}

	.blob {
		position: absolute;
		top: 0;
		left: 0;
		fill: var(--dominant-color);
		width: 60vmax;
		z-index: -1;
		animation: roll 10s ease-in-out infinite;
		transform-origin: 60% 60%;
	}

	.my-github,
	.email-me {
		letter-spacing: unset;
		text-transform: unset;
	}

	.contact-button-container {
		width: 100%;
		margin: 3rem 0;
	}

	.p-button-container {
		margin: 3rem 0 2rem 0;
	}

	.p-details-container {
		height: unset;
	}

	@keyframes roll {
		0% {
			transform: scale(1) translate(-55px, -260px);
		}
		38% {
			transform: scale(0.8, 1) translate(0vw, 30vh) rotate(160deg);
		}
		40% {
			transform: scale(0.8, 1) translate(0vw, 30vh) rotate(160deg);
		}
		78% {
			transform: scale(1.3) translate(0vw, 30vh) rotate(-20deg);
		}
		80% {
			transform: scale(1.3) translate(0vw, 30vh) rotate(-20deg);
		}
		100% {
			transform: scale(1) translate(-55px, -260px);
		}
	}
	.project-banner {
		font-size: 12rem;
	}
	.project-names-container {
		flex-direction: column;
	}
	.p-names {
		margin-left: unset;
		margin-bottom: 0.5rem;
	}

	.full-name,
	.email-me,
	.my-github {
		font-size: 1.2rem;
	}

	.footer-container {
		width: 91%;
	}

	.actions-container {
		align-items: center;
	}

	.copy-right {
		align-items: center;
	}

	.fa.fa-copyright {
		font-size: 10px;
	}

	.mobile-navigation {
		z-index: 7;
		cursor: pointer;
	}

	.subtitle {
		line-height: unset;
	}

	/* Individual product page */
	.top-image-container {
		min-height: 550px;
	}

	.bottom-image-container {
		flex-direction: column;
	}

	.mobile-image {
		margin: 2rem 0rem;
	}
}

@media (min-width: 1441px) {
	.banner-container,
	.p-container {
		margin: unset;
	}
}

/* accessibility */

a.focus-visible,
.p-button.focus-visible {
	outline: none;
	background-color: var(--dominant-color);
}

a.arrow-link.focus-visible {
	outline: none;
	background-color: white;
	color: var(--dominant-color);
}

a.about-para-blog.focus-visible {
	background-color: var(--dominant-color);

	color: black;
}

/* iphone 6, 7, 8 */

@media (max-width: 414px) {
	.p-button-container {
		text-align: center;
	}

	.p-button {
		padding: 1.5rem 12rem;
	}
}

@media (max-width: 320px) {
	.p-button {
		padding: 1.5rem 8rem;
	}

	.project-banner {
		font-size: 10rem;
	}

	.p-image-container {
		min-height: unset;
	}
}
